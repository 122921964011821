import * as React from "react";
import { Layout } from "../components/layout";
import { graphql } from "gatsby";

const LinksPage = (props) => {
  const data = props.data.allLinksYaml.edges;
  return (
    <>
      <Layout title="Links">
        <main className="container">
          <h1 className="mb-3">Links</h1>
          <div className="table-responsive">
            <table className="table table-striped table-bordered text-center">
              <tbody>
                {data.map(({ node }) => (
                  <tr>
                    <td>{node.name}</td>
                    <td>
                      <a href={"http://" + node.src}>{node.src}</a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </main>
      </Layout>
    </>
  );
};

export const query = graphql`
  query LinksPageQuery {
    allLinksYaml {
      edges {
        node {
          name
          src
        }
      }
    }
  }
`;

export default LinksPage;
